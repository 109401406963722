<template>
  <div>
    <intro v-if="!visible" @next="visible=true">서비스를 준비하고 계시는군요!<br>런치팩이 도와드릴께요 :)</intro>
    <div v-if="visible" class="container">
      <div class="subtitle1 main">내 서비스의 정보를 입력해주세요.</div>
      <!--<div class="body2 sub3" style="margin-top:12px">다음은 온라인에 공개적으로 노출되는 기본정보입니다.</div>-->
      <div style="text-align: left;width:368px;margin:60px auto 0 auto">
        <div class="body3 sub3" style="margin-bottom:8px">내 서비스의 이름
          <tool-tip style="margin-left:4px"
                    icon="u_question-circle"
                    text="온라인 검색 시 노출되는 서비스명입니다."/>
        </div>
        <input type="text" placeholder="텍스트" maxlength="30"
               :value="service.service_name" @keyup="e => service.service_name = e.target.value">
        <div class="body3 sub3" style="margin:28px 0 8px 0">내 서비스 아이디</div>
        <div class="position-relative">
          <input type="text" placeholder="영문,숫자" style="padding-right:148px" maxlength="20"
                 v-model="service.key" @input="onKeyupServiceId">
          <div class="main position-absolute body2" style="right:16px;top:10px">.launchpack.co.kr</div>
        </div>

        <button class="button is-primary body2-medium" style="width:100%;margin-top:72px" @click="$emit('next')">다음
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import Intro from "./Intro";
  import ToolTip from "../../component/ToolTip";

  export default {
    name: "Step0",
    components: {ToolTip, Intro},
    props: {
      service: {
        type: Object
      }
    },
    created() {

    },
    data() {
      return {
        visible: false
      }
    },
    methods: {
      onKeyupServiceId(e) {
        // eslint-disable-next-line no-useless-escape
        let regExp = /[\{\}\[\]\/?_.,;:|\)*~`!^\-+<>@\#$%&\\\=\(\'\"]/gi;
        // 특수문자 예외처리
        if (regExp.test(e.target.value)) {
          this.service.key = this.service.key.replace(regExp, '');
        } else if (e.target.value.length > 0 && /[a-zA-Z]/.test(e.target.value[0])) {
          if (this.is_hangul_char(e.data)) {
            e.target.value = e.target.value.substring(0, e.target.value.length - 1)
          }
          this.service.key = e.target.value;
        } else {
          this.service.key = '';
        }
        this.service.key = this.service.key.toLowerCase();
      },
      is_hangul_char(ch) {
        if (ch === null) {
          return false;
        }
        let c = ch.charCodeAt(0);
        return (0x1100 <= c && c <= 0x11FF) || (0x3130 <= c && c <= 0x318F) || (0xAC00 <= c && c <= 0xD7A3);
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .container
    position fixed
    top 50%
    left 50%
    transform translate(-50%, -50%)
    text-align center
</style>
